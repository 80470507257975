import { Injectable } from '@angular/core';
import { IFeatureDynamic } from '../models/feature-dynamic.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureDynamicService implements IFeatureDynamic {
  /**
   * Whether the feature media gallery (MEDIA_GALLERY) is enabled or not.
   */
  readonly mediaGallery: boolean;
  /**
   * Whether the feature member directory (MEMBER_DIRECTORY) is enabled or not.
   */
  readonly memberDirectory: boolean;
  /**
   * Whether the feature club service obligation (CLUB_SERVICE_OBLIGATION) is enabled or not.
   */
  readonly clubServiceObligation: boolean;
  /**
   * Whether the feature donation teaser (DONATION_TEASER) is enabled or not.
   */
  readonly donationTeaser: boolean;

  /**
   * Constructor initializing data
   */
  constructor() {
    this.mediaGallery = (environment.featuresDynamicDisabled?.indexOf('MEDIA_GALLERY') === -1) ? true : false;
    this.memberDirectory = (environment.featuresDynamicDisabled?.indexOf('MEMBER_DIRECTORY') === -1) ? true : false;
    this.clubServiceObligation = (environment.featuresDynamicDisabled?.indexOf('CLUB_SERVICE_OBLIGATION') === -1) ? true : false;
    this.donationTeaser = (environment.featuresDynamicDisabled?.indexOf('DONATION_TEASER') === -1) ? true : false;
  }
}
